export const projectData = [
    {
        name: "Patronum",
        client: "patronum",
        description: "Branding, Web Design, Marketing",
        src: "1.webp",
        year: 2023,
        link: "patronum-saas-case-study",
        srcSLider: "/assets/portfolio/mobile/patronum.webp",
    },
    {
        name: "Dharan",
        client: "dharan",
        description: "E-Commerce, Web Design, Development",
        src: "2.webp",
        year: 2023,
        link: "dharan-ecommerce-case-study",
        srcSLider: "/assets/portfolio/mobile/dharan.webp",
    },
    {
        name: "Kedarkala",
        client: "kedarkala",
        description: "Branding, Web Design",
        src: "3.webp",
        year: 2022,
        link: "kedarkala-portfolio-website-case-study",
        srcSLider: "/assets/portfolio/mobile/kedarkala2.webp",
    },
    {
        name: "Garden City Mall",
        client: "gcm",
        description: "Web Design, Development",
        src: "4.webp",
        year: 2022,
        link: "gcm-phygital-shopping-case-study",
        srcSLider: "/assets/portfolio/mobile/gcm.webp",
    },
    {
        name: "Quick X",
        client: "quick-x",
        description: "Branding, Web Design, Marketing",
        src: "5.webp",
        year: 2023,
        link: "quickx-crypto-case-study",
        srcSLider: "/assets/portfolio/mobile/quick.png",
    },
    {
        name: "Certvault",
        client: "certvault",
        description: "UX Planning, UI Design",
        src: "6.webp",
        year: 2022,
        link: "certvault-case-study",
        srcSLider: "/assets/portfolio/mobile/cert.png",
    },
    {
        name: "DMTCA",
        client: "dmtca",
        description: "Branding, Web Design",
        src: "7-2.webp",
        year: 2022,
        link: "dmtca-digital-branding-case-study",
        srcSLider: "/assets/portfolio/mobile/dmtca.png",
    },
    {
        name: "PDTL",
        client: "pdtl",
        description: "Web Design, Branding, Development",
        src: "9.webp",
        year: 2022,
        link: "pdtl-ecommerce-automation-case-study",
        srcSLider: "/assets/portfolio/mobile/pdtl.webp",
    },
    {
        name: "Wragby",
        client: "wragby",
        description: "UI/UX Design, Web Design, Development",
        src: "10.webp",
        year: 2022,
        link: "wragby-solutions-case-study",
        srcSLider: "/assets/portfolio/mobile/wragby.webp",
    },
    {
        name: "Patra",
        client: "patra",
        description: "UX Planning, UI Design, Development",
        src: "8.webp",
        year: 2022,
        link: "patra-saas-insurtech-case-study",
        srcSLider: "/assets/portfolio/mobile/patra1.webp",
    },
]